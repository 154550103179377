/* Responsive */
@media (min-width: 1200px) {
  div.container {
    width: 1200px;
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    width: 96% !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .fixed-btn .container {
    padding: 0;
  }
}

@media only screen and (max-width: 840px) and (orientation: portrait) {
  .quote-sec,
  .quote-img,
  .btn-text {
    display: none;
  }
  
  .px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pr-xs-0 {
    padding-right: 0 !important;
  }

  .pl-xs-0 {
    padding-left: 0 !important;
  }

  .filter-lbl {
    display: block;
  }

  .filter-sec {
    display: none !important;
  }

  #visits-filter {
    display: none;
  }

  #visits-filter:checked + label + .filter-sec {
      display: block !important;
  }

  .filter-lbl {
    position: absolute;
    top: 24px;
    right: 46px;
    z-index: 1;
    color: rgba(0, 0, 0, 0.54);
  }

  .left-menu {
    max-width: 100%;
    padding: 0;
  }

  .fixed-xs-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0 !important;
    background: #ffffff;
    padding: 10px 15px;
    z-index: 999;
    width: 100% !important;
    box-shadow: 0 -30px 30px rgba(0, 0, 0, 0.2) !important;
    justify-content: space-between;
  }

  .customer-sec.fixed-xs-btn {
    height: 76vh;
  }

  .float-btn {
    padding: 10px !important;
    border-radius: 50px !important;
    min-width: initial !important;
    box-shadow: 0 10px 30px #0000005c !important;
  }

  .float-btn svg {
    margin: 0;
    width: 34px;
    height: 34px;
  }

  .float-btn span {
    display: none;
  }

  .w-xs-100 {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media only screen and (max-width: 840px) {
  .h1 br {
    display: none;
  }

  .login-bg .container {
    flex-flow: column-reverse;
  }

  .login-bg .container .login-form, .login-bg .container .login-title {
      flex: 1 1;
      flex-basis: 100%;
      max-width: 100%;
      text-align: center;
  }

  .lg-sec {
    justify-content: center;
  }

  .login-bg {
    min-height: auto;
  }

  .footer-sec div {
    align-self: center;
    justify-content: center;
    display: flex;
  }

  .leftMenuInner a span {
    display: none;
  }

}

@media only screen and (max-width: 667px) {
  .booking-sec {
    min-height: initial;
  }

  .img-sec img {
    width: 100%;
    height: auto;
  }

  .date-picker-sec {
    border-top: 1px solid #f4f4f4;
    width: 100%;
    padding: 6px;
    justify-content: center;
  }

  body .react-daterange-picker__wrapper {
    border-left: 0;
  }

  .xs-pd-0 {
    padding: 0 !important;
  }

  .css-payment-modal {
    width: 90%;
  }

  .payment-modal-sec h5 input {
    width: auto;
  }

  .cs-data-grid {
    width: 1000px;
  }

  body {
    padding-top: 56px;
  }

  .lg-sec {
    height: auto;
  }

  .title-sec,
  .content-sec .title-sec,
  .form-sec .title-sec {
    left: 0 !important;
    width: 100% !important;
  }

  .align-xs-center {
    align-items: center !important;
  }

  .justify-xs-between {
    justify-content: space-between;
  }

  .h-xs-100 {
    height: 100% !important;
  }

  .p-xs-0 {
    padding: 0 !important;
  }

  .px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pr-xs-0 {
    padding-right: 0 !important;
  }

  .pl-xs-0 {
    padding-left: 0 !important;
  }
  
  .pr-xs-1 {
    padding-right: 15px;
  }

  .pl-xs-1 {
    padding-left: 15px;
  }

  .d-flex {
    display: flex;
  }

  .d-flex * {
    align-self: center;
  }

  .daterangepicker .drp-buttons .btn {
    font-size: 11px !important;
  }

  .quote-sec,
  .quote-img,
  .btn-text {
    display: none;
  }

  .css-modal,
  .search-form {
    width: 100%;
  }

  .search-form .form-control {
    min-width: auto !important;
  }

  div .refresh-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 4px 2px 4px 6px;
    min-width: auto;
    text-align: center;
    justify-content: center;
    display: flex !important;
    transition: all 0.5s ease;
  }

  .visit-table {
    height: auto;
  }

  .payment-filter:nth-child(2) {
    margin-left: 0;
  }

  .visit-datepicker {
    margin-top: 5px;
  }

  .visit-datepicker label {
    align-self: start !important;
  }

  .visit-datepicker span,
  .visit-datepicker input {
    font-size: 12px !important;
  }

  .visit-table tr {
    margin: 10px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding-top: 5px;
  }

  .visit-table td {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
  }

  .visit-table td:not(:nth-child(2)) {
    border-bottom: 0 !important;
  }

  .visit-table th:nth-child(1) {
    display: block;
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  .visit-table thead {
    display: none;
  }

  .menuList {
    height: auto;
    display: block;
    margin: 5px auto 0;
    position: initial;
    left: 0;
    right: 0;
  }

  .menuList ul {
    text-align: center;
  }

  .menuList ul li {
    padding: 3px 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .menuList ul li svg {
    margin-bottom: 6px;
  }

  .visit-table td img {
    align-self: center;
  }

  .visit-filter-check:checked + label {
    background: linear-gradient(to right, #e1288e, #ffa703) !important;
    color: #ffffff !important;
    border-color: #539b00 !important;
  }

  .visit-filter-check:checked + label + .visit-filter-sec {
    height: 208px;
    padding: 10px;
  }

  .menu-icon {
    top: 8px !important;
    left: 10px;
    right: initial !important;
    border: 0 !important;
  }

  .menu-icon svg {
    width: 32px;
    height: 32px;
  }

  .leftMenuInner {
    justify-content: end !important;
  }

  .logo-sec {
    display: none !important;
  }

  .menu-check:checked + label + .leftMenuInner  .logo-sec {
    display: flex !important;
    position: fixed;
    left: 0;
    top: 57px;
    background-color: #ffffff;
    width: 100%;
    z-index: 9;
    padding: 10px 15px;
    box-shadow: 0 10px 10px #00000017;
  }

  .menu-check + label {
    padding: 4px !important;
  }

  .leftMenu {
    padding: 0;
  }

  .leftMenu .container {
    padding: 5px;
  }

  .menu-check + label .menuClose,
  .menu-check:checked + label .menuOpen {
    display: none;
  }

  .menu-check:checked + label .menuClose {
    display: block;
  }

  .mt-xs-1 {
    margin-top: 1rem;
  }

  .inputField input {
    padding: 4px 10px;
  }

  .stat-sec {
    padding-top: 32px;
  }

  .customer-chart svg {
    width: 85% !important;
  }

  .jobcard-chart svg {
    width: 65% !important;
  }

  .my-xs-1 {
    margin: 1rem 0 !important;
  }

  .w-xs-100 {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .xs-column {
    flex-direction: column !important;
  }

  .xs-row {
    flex-direction: row !important;
  }

  .mt-xs-3 {
    margin-top: 3rem !important;
  }

  .m-xs-0 {
    margin: 0 !important;
  }

  .px-2-xs {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .dialogModal .MuiDialog-paper {
    min-width: 90% !important;
  }

  .DialogContent {
    width: 100% !important;
  }

  .MuiDialog-paper {
    margin: 10px !important;
    width: 90%;
  }

  .toolbar {
    flex: 1;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  .toolbar > div {
    justify-content: space-between;
  }

  .cs-modal {
    width: 95%;
  }

  .text-xs-left {
    text-align: left !important;
  }

  .text-xs-center {
    text-align: center !important;
  }

  .mb-jc-sb {
    justify-content: space-between !important;
  }

  .p-lg-2 {
    padding: 0;
  }

  .pt-xs-1 {
    padding-top: 1rem;
  }

  .pt-xs-0 {
    padding: 0 !important;
  }

  .vehicles-table {
    margin-top: 0px !important;
  }

  .prod-item {
    padding: 5px !important;
    margin: 0 !important;
    border-radius: 4px;
  }

  .prod-item * {
    font-size: 13px !important;
    padding: 0 !important;
  }

  button.remove-btn {
    padding: 10px 4px;
  }

  .left-menu {
    padding: 0;
    max-width: 100%;
  }

  .setting-page .left-menu {
    display: block;
  }

  .avatar-sec {
    justify-content: center;
  }

  .form-sec {
    padding: 15px;
    width: 100%;
  }

  .invoice-sec {
    padding-top: 65px;
  }

  .data-grid-box {
    overflow: auto;
  }

  .data-grid-box .data-grid {
    width: 1024px;
  }

  .headerReport {
    font-size: 134px;
  }

  .login-bg {
    padding: 0px !important;
    min-height: auto;
    text-align: center;
    border-radius: 0;
  }

  .h1 {
    font-size: 20px !important;
  }

  .lg-form {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }

  .login .logo-sec {
    display: flex !important;
  }

  .login .logo-sec img {
    width: 18px;
  }

  .login .form-sec {
    padding-left: 0;
    padding-right: 0;
  }

  .footer-sec {
    padding: 20px !important;
  }

  .footer-sec * {
    text-align: center;
    justify-content: center;
    font-size: 12px;
  }

  .client-sec .h2 {
    font-size: 16px;
    text-align: center;
  }

  .lg-form h1 {
    font-size: 24px;
  }

  .lg-sec .cs-form {
    margin-top: 30px !important;
  }

  .login-page .h1 {
    font-size: 24px !important;
    width: 80%;
    margin: auto;
  }

  .logo-sec h1 {
    font-size: 18px !important;
  }

  .login-sec {
    padding: 0;
  }

  .login-bg .container {
    display: flex;
    flex-direction: column-reverse;
  }

  .login-bg .container .login-form,
  .login-bg .container .login-title {
    flex: 1;
  }

  .login-bg .container .login-title {
    margin-bottom: 20px;
  }

  .content-sec .card h3 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .fixed-xs-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0 !important;
    background: #ffffff;
    padding: 10px 15px;
    z-index: 999;
    width: 100% !important;
    box-shadow: 0 -30px 30px rgba(0, 0, 0, 0.2) !important;
    justify-content: space-between;
  }

  .float-btn {
    padding: 10px !important;
    border-radius: 50px !important;
    min-width: initial !important;
    box-shadow: 0 10px 30px #0000005c !important;
  }

  .float-btn svg {
    margin: 0;
    width: 34px;
    height: 34px;
  }

  .float-btn span {
    display: none;
  }

  .add-customer-modal {
    height: 100%;
    overflow: auto;
    padding-bottom: 120px !important;
  }

  .customers-list {
    height: auto;
    max-height: 64vh;
  }

  .content-sec .card h2 span { 
    font-size: 12px;
  }

  .content-sec .card button img {
    margin-bottom: 0 !important;
  }

  .MuiInputLabel-shrink {
    padding-right: 10px !important;
  }

  .invoice-label {
    width: 60px;
  }
  
  .MuiModal-root .MuiDialog-paper {
    height: auto !important;
    border-radius: 6px !important;
    width: 100% !important;
  }

  .fixed-btn {
    padding: 15px;
  }
  
  .payment-tbl-cell div {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
  }

  .payment-tbl-cell {
      border-bottom: 1px solid #e3e3e3;
  }

  .btn.btn-sm {
    padding: 4px 10px !important;
    min-width: auto;
  }

  .filter-lbl {
    display: block;
  }

  .filter-sec {
    display: none !important;
  }

  #visits-filter {
    display: none;
  }

  #visits-filter:checked + label + .filter-sec {
      display: block !important;
  }

  .filter-lbl {
    position: absolute;
    top: 24px;
    right: 46px;
    z-index: 1;
    color: rgba(0, 0, 0, 0.54);
  }
}

@media only screen and (min-width: 670px) and (max-width: 1180px) {
  .clients-logo img {
    width: 140px;
  }
}