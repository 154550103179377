.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a:hover {
  text-decoration: none !important;
}

button {
  text-transform: initial !important;
}

th a,
td a,
.black-text,
.black-text *:not(.btn) {
  color: #000000 !important;
}

.p-lg-2 {
  padding: 2rem;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.p-h {
  padding: 0.5rem !important;
}

.m-2 {
  margin: 0.2rem !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.ml-h {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem;
}

.text-end {
  text-align: right;
}

.bb-1 {
  border-bottom: 1px solid #e3e3e3;
}

.bt-1 {
  border-top: 1px solid #e3e3e3;
}

.bl-1 {
  border-left: 1px solid #e3e3e3 !important;
}

.br-1 {
  border-right: 1px solid #e3e3e3;
}

.bb-0 {
  border-bottom: 0 !important;
}

.bl-0 {
  border-left: 0;
}

body .h4 {
  font-size: 18px !important;
}

.bg-color {
  /* background: -webkit-linear-gradient(left, #539b00, #4caf50);
  background: -moz-linear-gradient(left, #539b00, #4caf50);
  background: linear-gradient(to right, #539b00, #4caf50);
  padding-bottom: 20px; */
  background-color: #ffffff;
  background-image: url(images/bg-1.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: 0 -100px;
  min-height: calc(100vh - 66px);
}

.white-text,
.white-text * {
  color: #ffffff !important;
}

.op-h {
  opacity: 0.6;
}

.mt-0 {
  margin-top: 0 !important;
}

.h-100 {
  height: 100%;
}

.card {
  background-color: transparent !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #e3e3e3;
  transition: all 0.3s ease !important;
  position: relative;
}

.visit-card-box {
  background-color: #000000 !important;
  background-image: url(images/bg-2.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  border: 0;
}

.visit-card-box * {
  color: #ffffff !important;
}

.leftMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all 0.5s ease;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 2px 0;
}

.leftMenuInner {
  transition: all 0.5s ease;
  font-size: 14px;
}

.logo-img {
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
  border-top-left-radius: 10px;
  padding: 4px;
}

/*.topHeader {
  position: relative;
  margin-top: 25px;
  overflow: auto;
} 
.topHeader .card {
  align-self: flex-start;
  width: 100%;
  background-color: transparent;
  border: 0;
  border-radius: 50px !important;
}

.topHeader .card a {
  color: #ffffff;
  padding: 0px;
  margin: 5px 0px;
}

.topHeader .card a img {
  margin-right: 10px;
}

.topHeader .card.active {
  transform-origin: bottom;
  z-index: 1;
  background: #ffffff;
  border: 0;
}

.topHeader .card.active a {
  color: #000000;
}

.topHeader .card:hover {
  z-index: 2;
}

.topHeader .card a button {
  border-radius: 50px !important;
  display: flex;
  justify-content: start;
  transition: all 0.5s ease;
}

.topHeader .card a button:hover {
  background: rgb(255 255 255 / 12%);
}

.topHeader .card a span {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.topHeader .card a svg {
  font-size: 28px;
  margin-right: 10px;
  color: #000;
} */

.account-menu .MuiMenu-list a button {
  display: flex;
  justify-content: start;
}

.account-menu .MuiMenu-list a span {
  font-size: 14px;
  padding-left: 10px;
}

.card a {
  color: #000000;
}

.card a p {
  align-self: center;
  line-height: initial;
}

.card button {
  padding: 8px 10px;
}

.content-sec {
  width: 100% !important;
  position: relative;
  padding-top: 15px;
}

.content-sec .card h2 {
  font-size: 54px;
  font-weight: 500;
  line-height: normal;
}

.content-sec .card h3 {
  font-size: 18px;
  font-weight: 600;
}

.content-sec .card h2 span {
  font-size: 14px;
  display: block;
  color: #000000;
}

.content-sec .card button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content-sec .card button img {
  background: #00000009;
  border-radius: 10px;
  padding: 6px;
  width: 32px;
  height: 32px;
}

.content-sec .card a {
  position: relative;
  display: flex;
  flex-direction: column;
}

.form-sec {
  width: 100%;
  padding: 0 0px 50px 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.justify-content-between {
  justify-content: space-between;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-stretch {
  align-self: stretch;
}

.copyrights {
  text-align: center;
}

.plan-btn {
  width: 90%;
  white-space: normal !important;
  margin-bottom: 15px !important;
}

.footer {
  position: fixed;
  bottom: 10px;
}

#customerForm #businessId + fieldset,
#customerForm #customerId + fieldset {
  display: none;
}

.visitorStatus {
  position: absolute;
  background: #666666;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 11px !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
  min-width: 60px;
  text-align: center;
  transition: all 0.5s ease;
}

.visit-card .visitorStatus:after {
  content: "";
  height: 50px;
  width: 50px;
  position: absolute;
  top: -14px;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px dashed #666666;
  border-radius: 50px;
  z-index: -1;
}

.visit-card[data-status="Delivered"] .visit-type,
.visit-card[data-status="Delivered"] .visitorStatus {
  background-color: green;
}

.visit-card[data-status="Delivered"] .visitorStatus:after {
  border-color: green;
}

.visit-card[data-status="Open"] .visit-type,
.visit-card[data-status="Open"] .visitorStatus {
  background-color: orange;
}

.visit-card[data-status="Open"] .visitorStatus:after {
  border-color: orange;
}

.section-title {
  background: #ffffff;
  border-bottom: 1px solid #f4f4f4;
  padding: 10px;
  border-radius: 6px;
}

.datePicker,
.form-control.date-picker {
  border: 0 !important;
  border-left: 1px solid #e3e3e3 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  width: 174px;
  height: 100% !important;
  padding: 0px 10px !important;
}

.visit-count {
  background: #000000;
  color: #fff !important;
  border-radius: 20px;
  padding: 2px 6px 2px 2px;
  margin: 0 10px !important;
  font-size: 14px !important;
}

.visit-count strong {
  font-weight: 500;
  position: relative;
  top: -2px;
}

.visit-count svg {
  position: relative;
  top: 2px;
}

.count-tag {
  transition: all 0.5s ease;
  background-color: #ffffff;
  color: #000000;
  padding: 3px 8px;
  line-height: 20px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}

.customer-pic div,
.customer-pic svg {
  display: block;
  background-color: #f4f4f4;
  height: 44px;
  width: 44px;
  align-self: center;
  margin: auto;
  color: #cccccc;
  border-radius: 110px;
  overflow: hidden;
}

.customer-pic div {
  background-size: cover;
  background-position: center;
}

.customer-pic svg {
  font-size: 16px;
}

.card-item {
  transition: all 0.5s ease;
}

.card-item:hover {
  transform: scale(1.01);
}

.card-item .btn {
  padding: 3px 10px;
}

table thead th {
  font-weight: 700 !important;
}

p.model-year {
  font-size: 12px !important;
  color: #666666;
}

.title-sec {
  justify-content: space-between;
}

.title-sec h2 {
  padding: 15px 0;
  font-size: 26px;
  font-weight: 700;
}

.title-sec.title-xs {
  left: 360px;
  width: calc(100% - 580px) !important;
  position: relative;
  margin-bottom: 30px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.flew-wrap div {
  flex-wrap: wrap;
}

.flex-wrap .card {
  border-radius: 0 !important;
  border-right: 0;
  border-top: 0;
}

/* Tooltip */
.MuiTooltip-tooltip {
  font-size: 14px !important;
}

.MuiTooltip-popper {
  top: -5px !important;
}

.customer-chart div {
  align-self: center;
  margin: 20px 0;
}

.jobcard-chart div {
  align-self: center;
  margin-top: -60px;
}

.go-icon {
  vertical-align: middle;
  margin-left: 4px;
  position: relative;
  top: -1px;
}

a svg {
  position: relative;
  top: -1px;
}

.search-form {
  display: flex;
}

/* Toggle Menu */
.MuiMenu-paper {
  box-shadow: 3px 3px #ccc !important;
  border: 1px solid #ccc;
}

.account-menu .MuiMenu-paper {
  min-width: 240px !important;
  border-radius: 20px !important;
}

.MuiMenu-paper ul li {
  padding: 10px 15px !important;
  font-size: 16px;
}

.MuiMenu-paper ul li svg {
  /* margin-right: 6px; */
  font-size: 20px !important;
}

.MuiMenu-list .card.active {
  background-color: #000000;
  background: linear-gradient(to right, #e1288e, #ffa703) !important;
  border-radius: 0 !important;
}

.MuiMenu-list .card.active * {
  color: #ffffff !important;
}

.visit-card {
  position: absolute;
  top: 0;
  right: 0;
}

.visit-type {
  background-color: #666666;
  color: #ffffff;
  display: inline;
  border-bottom-left-radius: 20px;
  align-self: center;
  padding: 6px 18px;
}

.visit-table {
  position: relative;
  font-size: 14px;
  box-shadow: none !important;
}

.visit-table tr {
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
}

.visit-table tr:last-child td {
  border-bottom: 0 !important;
}

.visit-table tr:hover {
  background-color: #fbfbfb;
}

.visit-table tr:hover .visitorStatus {
  transform: translate(-50%, -50%) rotate(-15deg) scale(1.3);
}

.visit-table td {
  transition: all 0.5s ease;
  padding: 15px 16px;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.visit-table th {
  vertical-align: middle !important;
}

.visit-table th,
.visit-table td,
.visit-table td p {
  font-size: 14px;
  vertical-align: top;
}

.visit-table td p * {
  vertical-align: middle;
}

.visit-table td img {
  align-self: flex-start;
}

.visit-btn:after {
  background: #e3e3e340;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 20px;
  border-radius: 50px;
  z-index: 0;
  transform: scale(0) translate(-50%, -50%);
  transform-origin: top left;
  transition: all 0.5s ease;
}

.visit-btn:hover:after {
  transform: scale(1.2) translate(-50%, -50%);
  opacity: 1;
}

.visit-btn {
  padding: 5px 10px !important;
  top: -2px !important;
}

.visit-btn span {
  position: relative;
  z-index: 1;
}

.text-right,
.text-right input {
  text-align: right !important;
}

.more-btn {
  color: #666666 !important;
  cursor: pointer;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  text-align: center;
  margin: 0;
  transition: all 0.5s ease;
}

.more-btn:hover {
  background: #f4f4f4;
}

.more-btn svg {
  font-size: 24px !important;
}

#demo-simple-select {
  padding: 10px 14px;
}

.data-grid {
  box-shadow: none !important;
  border: 1px solid #cccccc !important;
  background-color: #ffffff;
}

.headerStyle * {
  font-weight: 600 !important;
}

.add-info input {
  padding: 10px;
}

.add-info {
  padding: 1px 0px 0px 6px !important;
  margin-right: -8px !important;
}

.remove-btn {
  min-width: auto !important;
}

.payment-card .btn {
  padding: 4px 4px;
}

.payment-card .btn svg {
  font-size: 18px !important;
}

.fade-menu {
  display: none;
}

.menuCheckbox:checked + .menuList {
  display: block;
}

.visit-table tr:hover .menuList {
  height: 32px;
  bottom: 1px;
}

.menuList {
  background: #ffffff;
  text-align: end;
  border-top: 1px solid #e7e7f1;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.menuList ul {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menuList ul li {
  padding: 10px 10px;
  cursor: pointer;
  border-right: 1px solid #e7e7f1;
  font-size: 13px !important;
  font-weight: 600;
  flex: 1;
  text-align: center;
}

.menuList ul li:last-child {
  border-right: 0;
}

.menuList ul li:hover {
  background-color: #f4f4f4;
}

.menuList ul li svg {
  font-size: 14px !important;
  margin-right: 4px;
  top: 2px;
  position: relative;
}

.menuList ul li span {
  vertical-align: middle;
}

.close-icon {
  position: absolute !important;
  top: 14px;
  right: 20px;
  z-index: 1;
  padding: 4px;
  width: 24px !important;
  height: 24px !important;
}

.closeMenu {
  width: 100%;
  padding: 0 0 10px 0;
  background-color: transparent !important;
  cursor: pointer;
}

.closeMenu svg {
  font-size: 24px !important;
  color: #ccc;
  transition: all 0.5s ease;
}

.closeMenu:hover svg {
  color: #666666;
}

.searchInput input {
  padding: 10px 15px;
}

.prod-input button {
  display: inline-block !important;
  padding: 5px 6px !important;
}

/* Collapse Menu */
.collapseMenu {
  padding-left: 88px;
}

.collapseMenu .title-sec {
  left: 88px;
  width: calc(100% - 88px) !important;
}

.collapseMenu .topHeader .card a button span {
  display: none;
}

.collapseMenu .leftMenuInner {
  width: 88px;
}

.collapseMenu .leftMenuInner .logo-text,
.collapseMenu .leftMenuInner .last-login-datetime {
  display: none;
}

.collapseMenu .leftMenuInner .last-login {
  width: 76%;
}

/* cs-modal */
.cs-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 36%);
}

.css-modal {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 36%);
  width: 600px;
  height: 100vh;
}

.modal-form .modal-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 15px 15px;
  border-top: 1px solid #ccc;
}

.modal-form .modal-dialog-content {
  margin-bottom: 52px;
}

/* Data Grid Searchbar */
.searchbar {
  width: 100% !important;
  padding: 10px !important;
  border-radius: 2px;
}

.searchbar .MuiInput-root {
  padding: 10px;
  background: #f4f4f4;
  border-radius: 10px;
}

.searchbar .MuiInput-root:after,
.searchbar .MuiInput-root::before {
  display: none;
}

h2.dialog-title {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
}

.ins-table tbody tr th,
.ins-table tbody tr td {
  padding-bottom: 0px;
  padding-top: 5px;
}

.icon-btn {
  cursor: pointer;
}

.number-input input {
  line-height: 42px;
  padding-right: 3px;
}

.invoice-table {
  height: calc(100vh - 220px);
  overflow: auto;
}

.disabled-form * {
  pointer-events: none;
}

.mb-txt {
  font-size: 12px;
  line-height: 20px;
}

.visit-filter-sec {
  padding: 10px 15px;
  margin-bottom: 40px;
}

.visit-filter-check {
  display: none !important;
  right: 52px !important;
  padding: 6px !important;
  z-index: 99;
}

.menu-check {
  display: none;
}

.branch-list {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
}

.branch-list:last-child {
  border-bottom: 0;
}

.modal-form {
  z-index: 9999 !important;
}

#indexLoading {
  width: 100%;
  height: 99vh;
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  left: 0;
  overflow: hidden;
}

#indexLoading p {
  align-self: center;
}

#indexLoading p img {
  width: 100px;
  margin: auto;
}

body .icon20 {
  font-size: 20px !important;
}

.expense-card[data-status="Active"] {
  cursor: pointer;
}

.expense-card[data-status="Void"] {
  background-color: #e3e3e3;
}

.logo-sec img {
  width: 32px;
  align-self: center;
}

.logo-sec h1 {
  font-size: 28px !important;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

.btn.menu-icon {
  display: none;
}

.payment-modal {
  width: 550px;
}

.payment-modal-sec h5 {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  font-weight: 400;
}

.payment-modal-sec h5 input {
  border: 0;
  font-weight: 700;
  width: 30%;
  word-wrap: break-word;
}

.css-payment-modal {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  min-height: 90px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 36%);
}

/* Temp fix */
.css-axafay-MuiDataGrid-virtualScroller {
  min-height: 150px;
}

.autocompleteAddressField {
  width: 100%;
  height: 52px;
  margin-top: 0;
  border-radius: 3px;
  padding: 16.5px 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

/* Modal - Popup Z-index customized */
.cs-modal-sec.MuiDialog-root.MuiModal-root {
  z-index: 1000;
}
.emp-page .MuiInputLabel-shrink {
  background: #fff;
  padding-right: 6px !important;
}

.MuiToolbar-root * {
  align-self: center;
  margin-bottom: 0;
}


.fixed-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  padding: 10px 20px;
  z-index: 9;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  text-align: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "Aspekta" !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.dropfocused {
  color: #111 !important;
}

/* Timeout Modal */
.session-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  backdrop-filter: blur(5px); /* apply blur filter */
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.session-modal .modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 30%;
}

.session-modal h2 {
  margin-top: 0;
}

.session-modal p {
  margin-bottom: 20px;
}

.session-modal button {
  width: 50%;
  padding: 10px 20px;
  /* margin-right: 10px; */
  border: 1px solid #cccccc;
  border-radius: 4px;

  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.MuiDialog-root h5 {
  color: #111111;
}

.warning-modal .MuiDialog-container h5 {
  position: relative;
  padding-left: 72px;
  display: flex;
}

.warning-modal .MuiDialog-container h5::before {
  content: "\26A0";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 62px;
  color: #f00;
  display: flex;
  align-self: center;
}
button.price-btn {
  padding: 0 2px;
  min-width: auto;
  height: 40px;
  color: #000000;
  margin: 0 !important;
  border-radius: 4px !important;
}

.currency-symbol {
  position: absolute;
  padding: 0px 10px;
}

.discount-label {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

div.discount-label .MuiFormControl-fullWidth {
  padding: 0px !important;
}

.discount-label .MuiSelect-select {
  padding-left: 5px !important;
}

.discount-label fieldset {
  border: 0;
}

.discount-label svg {
  right: 0;
}

.discount-label .inputField {
  border-left: 1px solid rgba(0, 0, 0, 0.23);
}

.btn.discount-remove-btn {
  padding: 0px 5px;
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 26px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-left: 0;
}
.invoice-btn-sec button {
  flex: auto;
}

.invoice-label {
  width: 160px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.containerStyle {
  position: relative;
  width: 900px;
  height: 50px;
  overflow: hidden;
  border: 2px solid rgb(220 7 7 / 50%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(249, 246, 255);
  text-align: center;
  margin-left: 174px;
  margin-bottom: 4px;
}

.textStyle {
  color: rgb(221 17 17);
  font-size: 18px;
}
.blinkingText {
  animation: blink 1s linear infinite;
}

.MuiAvatar-colorDefault {
  background: linear-gradient(to right, #e1288e, #ffa703) !important;
  font-size: 16px !important;
  font-weight: 700;
}

.no-img {
  background-color: #bdbdbd !important;
}

/* Calendar */
.MuiPickersCalendarHeader-root * {
  font-size: 14px;
}

.MuiDayCalendar-header * {
  font-size: 12px;
}

.css-1hbeyg3-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  background: linear-gradient(to right, #e1288e, #ffa703) !important;
  border: 0 !important;
  color: #ffffff;
}

/* Rating */
.MuiRating-root label * {
  font-size: 32px !important;
}

.MuiRating-root {
  justify-content: center;
}

.status-btn {
  position: absolute;
  top: 0;
  left: 0;
}

.profile-icon {
  position: absolute !important;
  top: 6px;
  right: 6px;
  z-index: 1;
}

.MuiDateCalendar-root button,
.MuiPickersPopper-paper button {
  font-size: 14px;
}

.MuiDataGrid-row,
.MuiDataGrid-cell {
  max-height: initial !important;
  min-height: initial !important;
}

.MuiDataGrid-cell {
  padding: 10px !important;
}

.MuiDataGrid-cell * {
  align-self: start;
}

.MuiDataGrid-virtualScroller {
  overflow-x: hidden !important;
  max-height: 600px !important;
  min-height: 52vh !important;
}

.MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border: 0;
}

.add-prod-box .MuiOutlinedInput-root {
  padding: 0;
}

.multi-payment .remove-btn {
  position: absolute !important;
  top: -38px;
  right: -16px;
}

.btn.add-btn {
  min-height: 40px;
  min-width: 40px;
  border-radius: 50px;
  padding: 4px;
  background-color: rgb(0, 0, 0);
}

.btn.add-btn:hover {
  background-color: rgb(0, 0, 0);
}

.add-btn svg {
  font-size: 24px !important;
}

.campaign-img {
  height: 200px;
  width: 100%;
  background-size: 100%;
}
.package {
  margin: 0 0;
  background: #f4f4f4;
  padding: 6px 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  list-style-position: inside;
  list-style-type: square;
}
