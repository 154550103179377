@import url("fonts/Aspekta/font-face.css");

body {
  font-family: "Aspekta" !important;
  color: #000000 !important;
}
button,
input {
  font-family: "Aspekta" !important;
  font-weight: 400;
}

*,
html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

ul {
  margin: 0;
}

a {
  color: #4a6684;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

label {
  font-weight: 500 !important;
}

label.error {
  display: none;
}

.p-a-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-x-h {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: 1rem !important;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-7 {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.px-10 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.m-h {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.mt-1,
.m-t-1 {
  margin-top: 1rem !important;
}

.mt-2,
.m-t-2 {
  margin-top: 2rem !important;
}

.mt-3,
.m-t-3 {
  margin-top: 3rem !important;
}

.mt-4,
.m-t-4 {
  margin-top: 4rem !important;
}

.mt-5,
.m-t-5 {
  margin-top: 5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2,
.m-b-2 {
  margin-bottom: 2rem !important;
}

.mb-1,
.m-b-1 {
  margin-bottom: 1rem !important;
}

.mb-h {
  margin-bottom: 0.5rem !important;
}

.mr-h {
  margin-right: 0.5rem;
}

.ml-h {
  margin-left: 0.5rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.align-center {
  align-self: center;
  align-items: center !important;
}

.w-100 {
  width: 100% !important;
}

body {
  font-size: 14px;
  color: #000000;
  padding-top: 66px;
}

.logo-sec {
  padding: 10px 0 10px 0;
}

.login {
  background: #ffffff url(images/bg-1.png) no-repeat bottom;
  background-size: cover;
  padding: 0px;
}

.login .title-sec {
  left: 0;
  width: 100% !important;
}

.logo a {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: flex;
  padding: 30px 0px;
}

.logo a img {
  width: 34px;
}

.logo a * {
  align-self: center;
}

.main {
  display: flex;
}

.lg-sec {
  display: flex;
  justify-content: end;
}

.bg-sec {
  background: #ffffff url(images/login-bg.jpg) no-repeat center;
  background-size: contain;
  position: relative;
}

.login-sec {
  padding: 0 15px 0 15px;
}

.login-sec {
  padding: 0 15px 0 15px;
}

.login-bg {
  background: #000000 url(images/login-bg.png) no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 60px !important;
  min-height: 80vh;
  display: flex;
  justify-content: end;
}

.login-page .h1 {
  font-weight: 650 !important;
  font-size: 42px !important;
}

.brand-title {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 2;
}

.brand-title h1 {
  font-size: 42px;
  font-weight: 300;
  margin-bottom: 6px;
}

.brand-title p {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.8;
}

/* Login Form */
.lg-form {
  width: 450px;
  padding: 40px;
  align-self: flex-end;
  background-color: #ffffff;
  border-radius: 30px;
}

.lg-form h1 {
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
}

.lg-form h2 {
  font-size: 28px;
}

.lg-form h3 {
  font-size: 13px;
  font-weight: normal;
  margin-top: 16px;
  opacity: 0.7;
  line-height: 20px;
}

.error {
  border: 1px solid #f00 !important;
}

.font-size-12,
.font-size-12 *,
.font-size-12 input {
  font-size: 12px !important;
}

.font-size-13,
.font-size-13 *,
.font-size-13 input {
  font-size: 13px !important;
}

.font-size-14,
.font-size-14 *,
.font-size-14 input {
  font-size: 14px !important;
}

.font-size-15,
.font-size-15 *,
.font-size-15 input {
  font-size: 15px !important;
}

.font-size-16,
.font-size-16 *,
.font-size-16 input {
  font-size: 16px !important;
}

.font-size-20,
.font-size-20 *,
.font-size-20 input {
  font-size: 20px !important;
}

.search-form input {
  padding: 13px 14px;
}

.form-control {
  height: auto !important;
}

.datePicker {
  margin: 0 10px;
}

button {
  font-size: 14px;
}

.white-color:hover {
  color: #ffffff !important;
}

.btn-success {
  white-space: inherit !important;
}

.btn-pan {
  margin: 0 20px !important;
}

.cs-btn {
  color: #ffffff;
  border: 0;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px 15px;
  position: relative;
  justify-content: center;
  width: 100%;
  transition: all 0.5s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background: #000000 !important;
  border: none !important;
  color: #ffffff;
}

.title-lbl {
  border-left: 4px solid #ffa703;
  padding-left: 10px;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.cs-btn.btn-sm {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
}

.cs-btn,
.btn-primary,
.cs-btn:hover,
.cs-btn:focus,
.cs-btn:active:focus {
  color: #ffffff !important;
  background: #000000 !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  padding: 8px 22px !important;
  box-shadow: none !important;
  border: 0 !important;
  white-space: nowrap;
  height: 46px;
  display: flex;
  align-items: center;
}

.cs-btn,
.cs-btn:hover,
.cs-btn:focus,
.cs-btn:active:focus,
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background: linear-gradient(to right, #e1288e, #ffa703) !important;
}

.leftMenuInner .cs-btn {
  display: flex;
  padding: 10px 22px !important;
  font-size: 16px !important;
}

.btn.btn-sm {
  font-size: 14px !important;
  padding: 4px 16px !important;
}

.btn-secondary {
  color: #000000 !important;
  background: transparent !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  padding: 8px 22px !important;
  box-shadow: none !important;
  border: 1px solid #cccccc !important;
  white-space: nowrap;
  height: 46px;
  display: flex;
  align-items: center;
}

.btn-cancel {
  background-color: #d32f2f !important;
}

button[aria-label="close"] svg {
  font-size: 24px !important;
}

.btn-primary svg,
.btn-secondary svg {
  font-size: 20px !important;
}

.btn-default {
  background-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
}

.copyrights {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  font-size: 12px;
  opacity: 0.8;
  box-sizing: border-box;
}

.copyrights a:first-child {
  margin-right: 5px;
}

/* OTP Section */
.otp-sec div:first-child {
  margin-left: 0;
}

.otp-sec div:last-child {
  margin-right: 0;
}

.otp-sec input {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
  align-self: end;
}

.text-white {
  color: #ffffff !important;
}

.left-menu {
  width: 100%;
  max-width: 320px;
  padding: 0px 40px 0 0px;
  /* position: absolute;
  left: 0;
  z-index: 9; */
  position: sticky;
  top: 70px;
}

.left-menu nav span {
  font-size: 13px;
}

.left-menu nav a {
  color: #000000;
}

.left-menu nav a.active {
  color: #ffffff;
}

.left-menu h3 {
  padding-left: 15px;
  margin-top: 20px;
}

.left-menu li a {
  border-radius: 4px;
}

.left-menu li a.active {
  /* background: linear-gradient(to right, #8bc34a, #4caf50); */
  background-color: #ffffff;
  color: #000000;
  border-right: 4px solid #e1288e;
}

.left-menu-inner {
  position: sticky;
  top: 70px;
}

.left-menu-inner nav {
  /* position: sticky;
  top: 70px; */
}

.left-menu-inner hr {
  margin: 10px 0;
}

.avatar-sec {
  padding: 0 15px;
  border-bottom: 1px solid #cccccc;
}

.profile-pic {
  border: 1px solid #cccccc;
}

.MuiMenu-paper .profile-pic {
  height: 52px !important;
  width: 52px !important;
  margin: 20px 0 0 0 !important;
}

.MuiMenu-paper .card {
  border: 0;
}

.avatar-sec h2 {
  margin: 5px 0 0px;
}

.last-login-datetime {
  border-top: 1px solid #cccccc;
}

.list-items li * {
  font-size: 14px !important;
}

.cs-form {
  padding: 10px;
}

.form-sec .cs-form {
  padding: 30px 30px 130px 30px;
  background-color: #ffffff;
  border-radius: 10px;
}

/* 
.cs-form *:not(svg),
.MuiMenu-list * {
  font-size: 14px !important;
} */

.cs-form label {
  color: #000000 !important;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  background: #fff;
  border-radius: 4px !important;
}

.cs-form input[type="text"],
.cs-form input[type="tel"],
.cs-form input[type="number"],
.cs-form input[type="email"],
.cs-form input[type="password"],
.country-option {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
}

.cs-form h2 {
  font-size: 24px !important;
  margin-bottom: 40px !important;
}

.lg-sec .cs-form {
  position: relative;
  padding: 0;
}

/* .page-title {
  font-size: 24px !important;
} */

.page-title input,
.page-title textarea {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.page-title legend, .page-title label {
    font-size: 18px;
}

.cs-form button {
  text-transform: none !important;
}

.cs-form .btn-default {
  border: 1px solid !important;
  padding: 5px 15px !important;
  border-radius: 4px !important;
}

.prize-tag span {
  display: inline-block;
}

.link-btn,
.link-btn:hover {
  display: inline-block !important;
  width: auto !important;
  color: #fff;
  padding: 0px 15px 4px 15px !important;
  border-radius: 4px !important;
}

.link-btn a {
  color: #fff !important;
  font-size: 14px;
}

.imgBox {
  display: inline-block;
  padding: 6px;
  margin: 10px 10px 10px 0;
}

input[type="hidden"] + fieldset {
  display: none;
}

.img-sec img {
  max-width: 100%;
  border-radius: 4px;
}

.back-btn {
  min-width: 30px !important;
  /* background-color: rgba(25, 118, 210, 0.04); */
  color: #666666 !important;
  padding: 0 !important;
  margin-right: 10px !important;
  top: -2px;
}

.back-btn svg {
  width: 24px;
  height: 30px;
}

.plan-btn {
  color: #000000 !important;
}

.freq-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
}

.freq-items-sec {
  white-space: nowrap;
  display: block !important;
  overflow: auto;
  height: 80px;
  max-width: 100%;
}

.freq-items-sec div {
  display: inline-block;
  white-space: nowrap;
}

.remove-icon {
  color: #ff0000;
}

.remove-icon * {
  cursor: pointer;
}

.inputField,
.inputField * {
  font-size: 14px !important;
}

.inputField input {
  padding: 10px 15px;
}

.input div {
  border-radius: 4px !important;
}

.staff-icon {
  align-self: center !important;
  width: 24px;
}

textarea {
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 10px;
}

.order-comp {
  background: #f8f8f8;
  border-top: 1px solid #e3e3e3;
}

.item-comp {
  border-left: 1px solid #ccc;
  padding-left: 10px;
}

.d-inline {
  display: inline-block;
  vertical-align: top;
}

.white-bg {
  background-color: #ffffff !important;
}

.float-btn {
  align-self: center;
}
.float-btn {
  align-self: center;
}

.customers-list {
  height: 90vh;
  overflow: auto;
  margin-top: 16px;
}

.booking-form input {
  height: 22px;
  font-size: 16px;
}

.booking-form label span {
  font-size: 14px;
}

.booking-form label {
  font-size: 14px;
  background-color: #ffffff;
  padding-right: 10px;
}

.prod-wrap {
  cursor: pointer;
}

.prod-input fieldset {
    height: 59px;
}

.prod-input label {
    font-size: 14px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.address-wrap {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  position: relative;
}

.address-wrap > label {
  position: absolute;
  top: -10px;
  left: 4px;
  font-size: 11px !important;
  background: #ffffff;
  padding: 0 25px 0 6px;
  color: rgba(0, 0, 0, 0.6);
}

.MuiChip-root.MuiChip-filled {
  height: auto;
  padding: 3px 0;
  font-size: 11px;
}

.pac-target-input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: inline-block;
  padding: 14px;
  width: 100%;
}

.pac-target-input-first {
  border: 1px solid rgba(255, 1, 1, 0.967);
  border-radius: 4px;
  display: inline-block;
  padding: 14px;
  width: 100%;
  outline: none;
}

.data-grid td,
.data-grid th {
  padding: 10px;
}

.popConus {
  padding: 0;
  text-align: center;
}

.login-content {
  background: #000;
  align-self: stretch;
}

.clients-logo img {
  width: 160px;
  max-width: 100%;
}

.footer-sec {
  font-weight: 300;
  background: #000000 url(images/login-bg.png) bottom;
  background-attachment: fixed;
  color: #ffffff;
  padding: 60px;
}

.footer-sec a,
.footer-sec a:hover {
  color: #ffffff;
  font-weight: 500;
}

.footer-sec a:hover {
  text-decoration: underline !important;
}

.MuiModal-root .MuiDialog-container {
  justify-content: end;
  padding: 0;
}

.MuiModal-root .MuiDialog-paper {
  width: 600px;
  margin: 0;
  align-self: stretch;
  height: 100%;
  max-height: 100% !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.MuiModal-root .MuiDialogTitle-root,
.MuiModal-root .MuiDialogActions-root {
  padding: 15px 30px !important;
}

.MuiModal-root .MuiDialogContent-root {
  padding: 30px !important;
}

.custom-prod {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: #ffffff;
  padding: 50px 30px;
  height: 100%;
  width: 400px;
  max-width: 100%;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-prod .modal-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  background: #ffffff;
  width: 100%;
  padding: 10px 30px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.23);
  z-index: 1;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.modal-footer {
  position: absolute;
  bottom: 30px;
  right: 30px;
  border: 0 !important;
  padding: 0 !important;
}

.rbc-btn-group {
  display: flex !important;
}

.filter-lbl,
#visits-filter {
  display: none;
}

.cs-form .toggleContainer {
   position: relative;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   width: fit-content;
   border: 2px solid #343434;
   background: #343434;
   font-weight: bold;
   color: #343434;
   cursor: pointer;
   font-size: 12px;
 }
 .cs-form .toggleContainer::before {
   content: '';
   position: absolute;
   width: 50%;
   height: 100%;
   left: 0%;
   border-radius: 2px;
   background: white;
   transition: all 0.3s;
 }
 .toggleCheckbox:checked + .toggleContainer::before {
    left: 50%;
 }
 .toggleContainer div {
   padding: 2px 6px;
   text-align: center;
   z-index: 1;
 }
 .toggleCheckbox {
   display: none;
 }
 .toggleCheckbox:checked + .toggleContainer div:first-child{
   color: white;
   transition: color 0.3s;
 }
 .toggleCheckbox:checked + .toggleContainer div:last-child{
   color: #343434;
   transition: color 0.3s;
 }
 .toggleCheckbox + .toggleContainer div:first-child{
   color: #343434;
   transition: color 0.3s;
 }
 .toggleCheckbox + .toggleContainer div:last-child{
   color: white;
   transition: color 0.3s;
 }

 .signinwith-btn {
    position: absolute !important;
    right: -10px;
    top: -6px;
    color: #000 !important;
    font-size: 18px !important;
}

.checkout-customer .customer-sec,
.checkout-customer .add-customer-modal {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
    border-radius: 0 !important;
    height: 100%;
    width: 600px;
}

.checkout-customer .customers-list {
  height: 82%;
}

/* Toggle Switch Style */
.toggle-switch input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
  display: none;
}

.toggle-switch label {
	cursor: pointer;
	text-indent: -9999px;
	width: 36px;
	height: 20px;
	background: #cccccc;
	display: block;
	border-radius: 50px;
	position: relative;
}

.toggle-switch label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 14px;
	height: 14px;
	background: #fff;
	border-radius: 50px;
	transition: 0.3s;
  box-shadow: 0 4px 10px #00000042;
}

.toggle-switch input:checked + label {
	background: #15C131;
}

.toggle-switch input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.MuiAccordion-root:before {
  display: none;
}