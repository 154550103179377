.react-daterange-picker {
  display: inline-flex;
  position: relative;
}

.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
}

.react-daterange-picker__inputGroup {
  /* min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2); */
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-daterange-picker__inputGroup__divider,
.react-daterange-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-daterange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-daterange-picker__button:enabled {
  cursor: pointer;
}

.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
  stroke: #0078d7;
}

.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}

.react-daterange-picker__button svg {
  display: inherit;
}

.react-daterange-picker__calendar {
  /* width: 350px; */
  max-width: 100vw;
  z-index: 999;
}

.react-calendar {
  width: auto !important;
}

.react-daterange-picker__calendar--closed {
  display: none;
}

.react-daterange-picker__calendar .react-calendar {
  border-width: thin;
}

.react-daterange-picker__calendar .react-calendar {
  border-color: #e3e3e3;
  box-shadow: 0 4px 10px #f4f4f4;
  border-radius: 10px;
  overflow: hidden;
}

.react-calendar .react-calendar__tile--now,
.react-calendar .react-calendar__tile--hasActive {
  color: #ffffff;
}

.react-calendar__tile--active,
.react-calendar__tile--hasActive {
  background: #000 !important;
  border-radius: 50px;
}

.react-calendar__tile--now {
    background: #ffff76;
    background: linear-gradient(to right, #E1288E, #FFA703) !important;
    border-radius: 50px;
}

.react-calendar .react-calendar__tile:disabled {
  background-color: #f9f9f9;
}

.react-calendar abbr[title] {
  text-decoration: none;
}

.react-calendar .react-calendar__tile--now:hover,
.react-calendar .react-calendar__tile--active:enabled:hover,
.react-calendar .react-calendar__tile--active:enabled:focus {
  background-color: #666666 !important;
}

.react-calendar__viewContainer button {
  padding: 0.8rem 0.4rem !important;
  justify-content: center;
}

.react-calendar .react-calendar__navigation button {
  border-left: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 0px 10px;
}

.react-calendar .react-calendar__navigation {
  margin-bottom: 0;
}

.react-daterange-picker__inputGroup__input:focus-visible {
  outline: none;
}
.react-calendar .react-calendar__navigation button:disabled {
  background-color: #f9f9f9;
}