.brandHome {
  padding-top: 0 !important;
}

.brandLogo {
  background-color: #fff;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  border-radius: 150px;
  align-self: center;
  color: #000 !important;
  text-align: center;
  line-height: 110px;
  font-size: 34px;
}

.cs-tabs button {
    background: transparent;
    color: #000000;
    margin: 10px 10px 10px 0;
    border-radius: 6px;
    min-height: 0;
    padding: 10px 20px;
    border: 0;
    transition: all 0.5s ease;
    font-size: 16px;
    border: 1px solid #e3e3e3;
}

.cs-tabs button:hover {
    background: #f4f4f4;
}

.cs-tabs button.Mui-selected {
    background: #000;
    color: #fff;
}

.cs-tabs .MuiTabs-indicator {
  display: none;
}

.select-service {
  position: absolute !important;
  right: 30px;
  top: 16px;
  z-index: 1;
  font-size: 22px;
  transform: scale(2);
}

.card label,
.select-service input {
  cursor: pointer;
}

.cs-tabs h4 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.cs-tabs span {
  font-size: 16px;
}

.cs-tabs .MuiTabs-scrollButtons {
    position: absolute;
    right: -10px;
    top: 0px;
    height: 100%;
    width: 40px;
    z-index: 1;
    background: #fff;
    opacity: 1;
}

.cs-tabs {
    position: relative;
}

.brandHome .MuiModal-root .MuiDialog-container {
  justify-content: center;
}

.brandHome .MuiModal-root .MuiDialog-paper {
  align-self: center;
  height: auto;
  border-radius: 10px;
}

.booknow-modal input {
  font-size: 3.4rem;
  font-weight: 500;
  letter-spacing: 12px;
  border-radius: 10px;
  padding: 15px 30px;
}

.booknow-modal .otp-field input {
  letter-spacing: 60px;
}

.booknow-modal fieldset {
  border-radius: 10px;
}

.booknow-modal .MuiPaper-root {
  padding: 30px 15px !important;
}

.booknow-modal .cs-btn.submitBtn {
  padding: 15px !important;
  border-radius: 10px !important;
  height: auto;
  font-size: 18px !important;
}

.booknow-modal .MuiDialogContent-root {
  padding: 10px 30px !important;
}

.confirmed-box {
    position: relative;
}

.confirmed-box:after {
    content: "";
    width: 0%;
    height: 0%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background-image: url(images/tinsel-explosion.png);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.5s 5s ease;
}

.bookingConfirmed .confirmed-box:after {
  width: 100%;
  height: 100%;
}